<template>
    <div class="static-content">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "static-content",
};
</script>

<style lang="scss">
.static-content {
    .description {
        a {
            color: #4f4f4f;
        }

        //p {
        //  font {
        //    font-size: 14px !important;
        //  }
        //}
    }

    .scrolled {
        overflow: scroll;
    }

    ul {
        list-style: disc;
        padding-left: 20px;

        li {
            padding: 5px 0;
        }
    }

    ol {
        padding-left: 20px;
        list-style: -moz-ethiopic-numeric;

        li {
            padding: 5px 0;
        }
    }
}
</style>
